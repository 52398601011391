export const libraryFilesState = {
  revisionAccepted: 'revisionAccepted',
};

export const RolesTypes = {
  client: 'client',
  company: 'user',
};

export const ComplaintTypeEnum = {
  queja: '1',
  tnc: '2',
};

export const ExtraPermissions = {
  complaint_manager: 'complaint_manager',
};
export const statusOptions = [
  {
    name: 'Cancelado',
    value: 'cancelled',
  },
  {
    name: 'Cerrado',
    value: 'closed',
  },
  {
    name: 'Finalizado',
    value: 'finished',
  },
  {
    name: 'Pendiente',
    value: 'pending',
  },
  {
    name: 'En progreso',
    value: 'inProgress',
  },
  {
    name: 'Devuelto',
    value: 'returned',
  },
];

export const complaintType = [
  {
    id: 1,
    name: 'Queja',
    letter: 'Q',
  },
  {
    id: 2,
    name: 'Trabajo no conforme',
    letter: 'TNC',
  },
];

export const SystemRoles = {
  operative: 'operative',
  rsg_user: 'rsg_user',
  chief_coordinator: 'chief_coordinator',
  area_coordinator: 'area_coordinator',
  admin: 'admin',
  front_desktop: 'front_desktop',
  analyst: 'analyst',
};
